.TopBar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(31, 31, 31, 0.5);
  height: 70px;
  width: 100%;
  position: fixed;
  top: 0px;
  margin-bottom: auto;

  .Logo {
    width: 100%;
  }

  .Menu {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;

    .title {
      color: rgb(255, 255, 255);
      cursor: pointer;

      &:hover {
        color: rgb(3, 98, 161);
      }

      &:not(first-child) {
        margin-left: 15px;
      }
    }
  }
}
