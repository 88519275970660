.Home {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-image: url(./../../static/images/wallpaper.jpg);
  background-repeat: no-repeat;

  .Content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: rgba(37, 37, 37, 0.65);

    .Block {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      .Title {
        color: rgb(240, 240, 240);
        font-size: 40px;
      }

      .SubTitle {
        color: rgb(3, 98, 161);
        font-size: 34px;
      }
    }
  }
}
